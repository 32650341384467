quill-view-html.description-quill-view {
  .ql-container {
    height: auto;
    font-size: 1.6rem;
    .ql-editor {
      height: auto;
    }
    .ql-editor  * {
      background-color: transparent !important;
    }
  }
}
