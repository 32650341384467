.ngucarousel-items {
  .item {
    padding: 0;
    .tile {
      box-shadow: none;
      height: 28.2rem;
      @include breakpoint-up-md {
        height: 61.4rem;
      }
      .slide-image {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

.carousel-points {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  margin: 0;
  list-style-type: none;
  li {
    height: 1rem;
    width: 1rem;
    margin: 0 0.6rem;
    background-color: map-get($colors, 'gray');
    border-radius: 50%;
    &.active {
      background-color: var(--color-primary);
    }
  }
}
