@import "variables";

select.custom-select {
  -moz-appearance: none;
	-webkit-appearance: none;
  display: block;
  box-sizing: border-box;
  position: relative;
  font-size: 1.6rem;
  padding: 1.6rem 3rem 1.6rem 1.6rem;
  border: 1px solid $white;
  border-radius: 0.4rem;
  background-color: $white;
  margin: 0;
  background-image: url('../assets/images/icons/icon-caret-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 50%;
  outline: none;
  option {
    width: 100%;
    word-wrap: break-word;
  }
  &.bordered {
    border: 1px solid $gray-medium-light;
  }
  &::-ms-expand {
    display: none;
  }
}

.custom-checkbox,
.custom-radio-btn {
  position: relative;
  padding-left: 3rem;
  font-size: 1.9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
  }

  label {
    font-style: normal;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.2rem;
    width: 2.2rem;
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    transition: background-color 100ms ease-in-out, border-color 150ms ease-in-out;
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
      top: -12px;
    }
    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 0.25rem;
      left: 0.6rem;
      height: 1rem;
      width: 0.5rem;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(45deg);
      box-sizing: content-box;
    }
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    & ~ .checkmark {
      background-color: var(--color-primary);
      border-color: var(--color-primary);

    }
    & ~ .checkmark:after {
      display: block;
    }
  }

  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    & ~ .checkmark {
      outline: 2px var(--color-primary);
    }
  }
}
